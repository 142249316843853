// Check if user is a platform-level admin. Used for displaying beta elements in nav and other admin features.
export function useIsPlatformAdmin() {
  // const user = useSupabaseUser()
  const { user } = useUser()
  if (!user) { return false }
  if (user && user.value) {
    const email = user?.value?.primaryEmailAddress?.emailAddress.toLowerCase()
    if (!email) { return false }
    return email.endsWith('soil-link.co.uk') || email.endsWith('soillink.email')
  }
  return false
}

export function useUserIsDeveloper() {
  const { user } = useUser()
  if (!user) { return false }
  const email = user?.value?.primaryEmailAddress?.emailAddress.toLowerCase()
  if (!email) { return false }
  return email.endsWith('soil-link.co.uk') || email.endsWith('soillink.email')
}
